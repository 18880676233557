//
// Docs color palette classes
//

@each $color, $value in $colors {
  .swatch-#{$color} {
    background-color: #{$value};
    @include color-yiq($value);
  }
}

@each $color, $value in $theme-colors {
  .swatch-#{$color} {
    background-color: #{$value};
    @include color-yiq($value);
  }
}

@each $color, $value in $grays {
  .swatch-#{$color} {
    background-color: #{$value};
    @include color-yiq($value);
  }
}
