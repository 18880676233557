// scss-lint:disable ImportantRule

//
// Responsive tests
//

// Responsive (scrollable) doc tables
.table-responsive .highlight pre {
  white-space: normal;
}

// Utility classes table
.bd-table th small {
  display: block;
  font-weight: normal;
  color: #999;
}
